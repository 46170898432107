
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseButton from '@/components/baseComponents/BaseButton.vue';

@Component({
  components: { BaseButton },
})
export default class IntegrationsSlide extends Vue {
  @Prop({ default: {} }) card!: Record<string, unknown>;
  @Prop({ default: 0 }) id!: number;
  @Prop({ default: 0 }) currentSlide!: number;

  get cardBoxShadow(): string {
    return this.id >= this.currentSlide && this.card.backgroundColor !== 'transparent' ? '0.4' : '0';
  }

  goToLanding(landing: string): void {
    this.$router.push({ name: landing });
  }
}
