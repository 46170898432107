
import InsightsSlide from '@/components/creditpushMain/HomePage/InsightsSlide.vue';
import { Carousel, Slide } from 'vue-carousel';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import NavigationCarouselComponent from '@/components/creditpushMain/HomePage/NavigationCarouselComponent.vue';

const FAKE_SLIDES = 3;

@Component({
  components: {
    Carousel,
    Slide,
    InsightsSlide,
    NavigationCarouselComponent,
  },
  computed: {
    ...mapGetters('calculateWindowWidth', ['isDesktopSize']),
  },
})
export default class Insights extends Vue {
  public isDesktopSize!: boolean;

  currentSlide = 0;
  cardWidth = 248;
  paddingBetweenCards = 14;
  visibleCardsCount = 4;
  cards = [
    {
      title: 'Your all-in-one small business dashboard',
      description:
        'Helping you better understand the health of your business via insight-based interactive dashboards and other tools.',
      backgroundColor: '#FF984E',
      link: 'Landing360Page',
    },
    {
      title: 'How much is your business worth?',
      description: 'Calculate the value of your small or medium business with our FREE business valuation calculator.',
      backgroundColor: '#ADFF00',
      link: 'LandingValuationPage',
    },
    {
      title: 'Check your business credit score for free',
      description:
        'Check and boost your business credit score with this powerful suite of data-driven financial tools.',
      backgroundColor: '#D4D81E',
      link: 'LandingCreditScorePage',
    },
    // {
    //   title: 'Small business loans assistance',
    //   description: 'Improve your business credit score and find great financing options for your business.',
    //   backgroundColor: '#FF4E6E',
    //   link: 'LandingLoanPage',
    // },
    {
      title: 'Calculate your carbon footprint',
      description:
        'Let the carbon footprint calculator to guide you with all its tips, suggestions & insights on how to become an eco-conscious business leader.',
      backgroundColor: '#5C7AC5',
      link: 'LandingCarbonPage',
    },
    {
      title: '',
      description: '',
      backgroundColor: 'transparent',
      link: 'default1',
    },
    {
      title: '',
      description: '',
      backgroundColor: 'transparent',
      link: 'default2',
    },
    {
      title: '',
      description: '',
      backgroundColor: 'transparent',
      link: 'default3',
    },
  ];

  get filteredCards(): any {
    return this.cards.filter((card: Record<string, unknown>) => card.link !== this.$route.name);
  }
  get carouselWidth(): string {
    return `${this.visibleCardsCount * this.cardWidth + this.paddingBetweenCards}px`;
  }
  get progressBarWidth(): string {
    return ((100 / (this.filteredCards.length - FAKE_SLIDES)) * (this.currentSlide + 1)).toFixed(2).toString();
  }

  prevSlide(): void {
    if (this.currentSlide === 0) this.currentSlide = this.filteredCards.length - FAKE_SLIDES;
    else this.currentSlide -= 1;
  }
  nextSlide(): void {
    if (this.currentSlide === this.filteredCards.length - 1 - FAKE_SLIDES) this.currentSlide = 0;
    else this.currentSlide += 1;
  }
  changeSlide(index: number): void {
    this.currentSlide = index;
  }
}
