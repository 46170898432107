
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconArrow from '@/components/icons/IconArrow.vue';

@Component({
  components: {
    IconArrow,
  },
})
export default class NavigationCarouselComponent extends Vue {
  @Prop({ default: '' }) progressBarWidth!: string;
  @Prop({ default: false }) isWhiteStyle!: boolean;

  prevSlide(): void {
    this.$emit('prevSlide');
  }
  nextSlide(): void {
    this.$emit('nextSlide');
  }
}
